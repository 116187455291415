// tslint:disable-next-line:only-arrow-functions
export const URL_SITE = ( function() {
    // return 'http://localhost:83/api';
    if ( location.hostname === 'localhost') {
        return 'http://localhost:83/api'
        //return 'https://portal-color.theappsonline.com/api'
        //return 'https:///cmportal.ro';
    } else {
        //return 'http://localhost/api'
        //return 'https://regis.theappsonline.com/api'
        return 'https://b2b.odocs.ro';
    }
})();

export const URL_API = ( function() {
    // return 'http://localhost:83/api';
    if ( location.hostname === 'localhost') {
        return 'http://localhost:83/api'
        //return 'https://portal-color.theappsonline.com/api'
        //return 'https:///cmportal.ro/api';
    } else {
        //return 'http://localhost/api'
        //return 'https://regis.theappsonline.com/api'
        return '/api';
    }
})();

export const URL_API_FILE = ( function() {
    // return 'http://localhost:83/api';
    if ( location.hostname === 'localhost') {
        return 'http://localhost:83/api'
        //return 'https://regis.theappsonline.com/api'
        //return 'http://regisdra-test/api';
    } else {
        //return 'http://localhost/api'
        //return 'https://regis.theappsonline.com/api'
        //return '/api';
        return 'http://regisdra-files/api';
    }
})();

export const URL_WORKERS = ( function() {
    if ( location.hostname === 'localhost') {
        return ''
        //return 'https://portal-color.theappsonline.com/api'
        //return 'https:///cmportal.ro/api';
    } else {
        //return 'http://localhost/api'
        //return 'https://regis.theappsonline.com/api'
        return '';
    }
})();
// tslint:disable-next-line:only-arrow-functions

export const CONFIG_ENV = {
    URL_USER: {
        getCurrentUser : URL_API + '/user/get_current_user',
        loginUser : URL_API + '/user/login',
        saveMyProfile : URL_API+ "/user/save_my_profile",
        getMyImageProfileAsString: URL_API + '/user/my_image_profile_as_string',
        getMyImageProfile : URL_API+ "/user/get_my_image_profile",
        uploadImageMyProfile : URL_API+ "/user/upload_image_my_profile",
        getMyColorMetalUserContacts : URL_API+ "/user/color_contacts",
        changePassword : URL_API + '/user/change_password'
    },
    URL_ADMIN: {
        users : URL_API + '/admin/users',
        userDetails: URL_API + '/admin/users/user_details',
        resetPassword : URL_API + '/admin/users/reset_password',
        saveNewUser : URL_API + '/admin/users/new_user',
        getCompanyByAppid: URL_API + '/admin/companies/company_by_appid',
        getCompanyByCif: URL_API + '/admin/companies/company_by_cif',
        getCompanyAddress: URL_API + '/admin/companies/get_company_address',
        getCompaniesByFilter: URL_API + '/admin/companies/filter',
        getOnePageCompanies: URL_API + '/admin/companies/page',
        nomCategory: URL_API + '/admin/category',
        nomProduct: URL_API + '/admin/product',
        imageCategoryAsString: URL_API + '/admin/category/image_as_string',
        treeDataCategories: URL_API + '/admin/category/treedata',
        listDataCategories: URL_API + '/admin/category/listdata'
    },

    URL_USER_ADRESE_LIVRARE: {
        adresa : URL_API + '/user/adrese_livrare',
        adrese : URL_API + '/user/adrese_livrare',
        getAdresa: URL_API + '/user/get_adresa'
    },

    URL_COMPANY_USERS: {
        allUsers : URL_API + '/user/company_users',
    },

    URL_ARTICOL:{
       articol: URL_API + '/articol',
       browseArticles: URL_API + '/browse/articles',
       identifyArticleInDB: URL_API + '/browse/identify_article',
       identifyArticleInDBAndPutIntoFavorites : URL_API + '/favorites/identify_article_and_put_in_favorites',
       putArticlebyCodeIntoFavorites : URL_API + '/favorites/put_article_by_code_in_favorite',
       identifyArticleInDBAndPutIntoBasket : URL_API + '/basket/identify_article_and_put_in_basket',
       putArticlebyCodeIntoBasket : URL_API + '/basket/put_article_by_code_in_basket',
       favorites: URL_API + '/favorites',
       basket: URL_API + '/basket',
       calculDimensiuniDebitare: URL_API + '/calculator',
       articolByProductCode: URL_API + '/articol/by_product_code',
       get_article_stock: URL_API + '/get_article_stock'
    },

    URL_CATEGORY:{
        browseCategories: URL_API + '/browse/categories',
        getJPG: URL_API + '/browse/category/image',
    },

    URL_OFFER:{
        sendBasketForAnOffer: URL_API + '/offer/send_basket_for_an_offer',
        sendCerereForNewOffer: URL_API + '/offer/send_cerere_for_new_offer',
        getMyOffers: URL_API + '/offer/my_offers',
        OneOffer: URL_API + '/offer/offer',
        acceptOffer: URL_API + '/offer/accept_offer',
        sendCerereForNewOffer2: URL_API + '/offer/send_cerere_for_new_offer_2',
    },

    URL_CERERE:{
        cerere: URL_API + '/cerere',
    },

    URL_INVOICE:{
        getMyInvoices: URL_API + '/invoice',
        download_invoice: URL_API + '/invoice',
        getInvoicesFromNAV: URL_API + '/invoice/invoiceNAV',
        getInvoice: URL_API + '/invoice/single_invoice',
        download_certificate: URL_API + '/invoice/certificate',
        getFilteredInvoices: URL_API + '/invoice/filtered_invoices'
    },

    URL_BALANTA:{
        getMyAccountingBalanceJson: URL_API + '/my_accounting_balance/json',
        getPdf: URL_API + '/my_accounting_balance/pdf',
        getPdfMobile: URL_API + '/my_accounting_balance/pdf_mobile'
    },

    URL_RESET_PASSWORD: {
        checkToken: URL_API + "/reset_password/check_token"
    },

    URL_ALERTS: {
        getMyAlerts: URL_API + "/alerts/get_alerts"
    },

    URL_COUNTER: {
        getCounters: URL_API + "/count_new_data"
    },

    URL_DOWNLOAD: {
        generare_oferta: URL_API + '/offer/generte_offer',
        generare_oferta_pdf_link: URL_API + '/offer/generare_oferta_pdf_link',
        generare_pdf_privacy: URL_API + '/generare_pdf_privacy'
    },

    URL_USER_COMPANIES: {
        getUserCompanies: URL_API + '/companies',
        changeUserCompany: URL_API + '/companies/change_company_user'
    },

    myLocale: {
        /* starting with Sunday */
        days: 'Duminica_Luni_Marti_Miercuri_Joi_Vineri_Sambata'.split('_'),
        daysShort: 'Dum_Lun_Mar_Mie_Joi_Vie_Sam'.split('_'),
        months: 'Ianuarie_Februarie_Martie_Aprilie_Mai_Iunie_Iulie_August_Septembrie_Octombrie_Noiembrie_Decembrie'.split('_'),
        monthsShort: 'Ian_Feb_Mar_Apr_Mai_Iun_Iul_Aug_Sep_Oct_Nov_Dec'.split('_'),
        firstDayOfWeek: 1
    }
}

export const ArticolNull={
    appid:'',
    pid:'',
    categoryPid:'',
    lantHierarchyCategories: '',
    code: '',
    name:'',
    UMBase:'',
    isActive:false,
    withLength: false,
    withWidth: false,
    withThickness: false,
    withDiameter: false,
    sizeLength:null,
    sizeWidth:null,
    sizeThickness:null,
    sizeDiameter:null
}

export const ArticolNullInBasket = {
    appid:'',
    productPid:'',
    categoryPid: '',
    productCode:'',
    productName: '',
    categoryName: '',
    qBuc: 0,
    q_um_base: 0,
    qUm1 :null,
    qUm2: null,
    um1: null,
    um2: null,
    l: null,
    w:null,
    t:null,
    d:null,
    h:null,
    a:null,
    k:null,
    g:null,
    dorescDebitare:false,
    cuttingLength:null,
    cuttingWidth:null
}